<template>
  <div>
    <text-element
      :text="queue.category"
      :layout="customLayout.service"
      :skeleton="skeleton"
      v-if="isServiceVisible"
    ></text-element>
    <text-element
      :text="queue.number"
      :layout="customLayout.number"
      :skeleton="skeleton"
      :customClass="queue.animation"
      class="font-bold"
    ></text-element>
    <text-element
      :text="queue.seat"
      :layout="customLayout.seat"
      :skeleton="skeleton"
      v-if="isSeatVisible"
    ></text-element>
    <text-element
      :text="queue.seatSign"
      :layout="customLayout.seatsign"
      :skeleton="skeleton"
      v-if="isSeatSignVisible"
    ></text-element>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "QueueElementTemplate",
  mixins: [layoutMixin],
  components: {
    TextElement: () => import("@/components/core/TextElement"),
  },
  props: {
    queue: Object,
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },

  destroyed() {
    //remotecontrol.dispose(this.queue.deviceId);
  },
  computed: {
    customLayout: function() {
      var l = cloneDeep(this.layout);
      if (this.queue.queueCustomized) {
        l.number.color = this.queue.queueNumberColor;
        l.number.background.param = this.queue.queueNumberBackgroundColor;

        l.seat.color = this.queue.queueSeatColor;
        l.seat.background.param = this.queue.queueSeatBackgroundColor;

        l.seatsign.color = this.queue.queueSeatColor;
        l.seatsign.background.param = this.queue.queueSeatBackgroundColor;

        l.service.color = this.queue.queueServiceColor;
        l.service.background.param = this.queue.queueServiceBackgroundColor;

      }

      if (!this.queue.seat || !this.queue.seatSign) {
          l.number.positions[0].height += l.seat.positions[0].height;
        }

      return l;
    },
    isServiceVisible: function() {
      return (
        this.customLayout.service.visible &&
        this.queue.category !== undefined &&
        this.queue.category !== null &&
        this.queue.category.length > 0
      );
    },
    isSeatVisible: function() {
      return (
        this.customLayout.seat.visible &&
        this.queue.seat !== undefined &&
        this.queue.seat !== null &&
        this.queue.seat.length > 0
      );
    },
    isSeatSignVisible: function() {
      return (
        this.customLayout.seatsign.visible &&
        this.queue.seatSign !== undefined &&
        this.queue.seatSign !== null &&
        this.queue.seatSign.length > 0
      );
    },
  },
  methods: {
    //
  },
};
</script>
<style>
@keyframes queue-flash {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    opacity: 1;
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    opacity: 0;
  }
}

.queue-animation {
  animation: queue-flash 4s;
}
</style>
